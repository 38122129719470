import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import './Gallery.css';

function Gallery() {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [modalImage, setModalImage] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/api/galleries?populate=image`)
      .then(response => {
        const itemsData = response.data.data.map(item => {
          const name = item.name || t('noNameAvailable');
          const imageUrl = 
            item.image?.formats?.medium?.url
              ? `${API_URL}${item.image.formats.medium.url}`
              : item.image?.formats?.small?.url
              ? `${API_URL}${item.image.formats.small.url}`
              : item.image?.url
              ? `${API_URL}${item.image.url}`
              : null;
  
          return {
            id: item.id,
            name,
            imageUrl,
          };
        });
        setItems(itemsData);
      })
      .catch(error => console.error('Error fetching gallery items:', error));
  }, [API_URL, t]);
  

  const handleImageClick = (imageUrl) => {
    setModalImage(imageUrl);
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalImage(null);
  };

  return (
    <div className="container mt-5">
      <h2 className="mb-4">{t('gallery')}</h2>

      <div className="row">
        {items.map((item) => (
          <div key={item.id} className="col-6 col-md-4 col-lg-3 mb-4">
            <div className="card h-100 shadow-sm">
              {item.imageUrl ? (
                <img
                  src={item.imageUrl}
                  alt={item.name || t('image')}
                  className="card-img-top"
                  style={{ cursor: 'pointer', height: '300px', objectFit: 'cover' }}
                  onClick={() => handleImageClick(item.imageUrl)}
                />
              ) : (
                <div className="card-img-top text-center p-2">
                  {t('noImageAvailable')}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {/* Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <Modal.Body>
          {modalImage && (
            <img src={modalImage} alt={t('fullSizeImage')} className="img-fluid" />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            {t('close')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Gallery;
