import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import HeaderImage from '../components/HeaderImage';

function Home({ onAboutClick, onPartnersClick }) {
  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTarget = params.get('scroll');
    if (scrollTarget === 'about') {
      onAboutClick();
    } else if (scrollTarget === 'partners') {
      onPartnersClick();
    }
  }, [location.search, onAboutClick, onPartnersClick]);

  return (
    <div className="container mt-5">
      <HeaderImage />
    </div>
  );
}

export default Home;
