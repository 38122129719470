import React, { useState, useEffect } from 'react';
import { Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Header.css';

function Header({ onAboutClick, onPartnersClick }) {
  const API_URL = process.env.REACT_APP_API_URL;
  const { t, i18n } = useTranslation();
  const [isScrolled, setIsScrolled] = useState(false);
  const [mainIconUrl, setMainIconUrl] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchMainIcon = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/main-icons?populate=*`);
        const iconUrl = response.data.data[0]?.icon?.url;
        setMainIconUrl(`${API_URL}${iconUrl}`);
      } catch (error) {
        console.error('Error fetching main icon:', error);
      }
    };
    fetchMainIcon();
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 100);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleAboutClick = (e) => {
    e.preventDefault();
    if (location.pathname === '/') {
      onAboutClick();
    } else {
      navigate('/'); 
      setTimeout(() => {
        onAboutClick(); 
      }, 0);
    }
  };
  
  const handlePartnersClick = (e) => {
    e.preventDefault();
    if (location.pathname === '/') {
      onPartnersClick();
    } else {
      navigate('/');
      setTimeout(() => {
        onPartnersClick();
      }, 0);
    }
  };
  

  const changeLanguage = async (lng) => {
    await i18n.changeLanguage(lng);
    if (location.pathname !== '/') {
      navigate('/');
    }
  };

  const currentLanguage = i18n.language;

  return (
    <>
      <Navbar expand="lg" className="custom-navbar">
        <Container>
          <Navbar.Brand as={Link} to="/">
            {mainIconUrl ? <img src={mainIconUrl} alt="Main Icon" /> : 'Loading...'}
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <i className="bi bi-list-ul"></i>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link as={Link} to="/">{t('home')}</Nav.Link>
              <Nav.Link onClick={handleAboutClick}>{t('about')}</Nav.Link>
              <Nav.Link as={Link} to="/products">{t('products')}</Nav.Link>
              <Nav.Link as={Link} to="/gallery">{t('gallery')}</Nav.Link>
              <Nav.Link onClick={handlePartnersClick}>{t('partners')}</Nav.Link>
            </Nav>
            <Dropdown className="ms-3">
              <Dropdown.Toggle variant="secondary" id="dropdown-basic" className="language-dropdown">
                {currentLanguage === 'ru' ? 'Рус' : currentLanguage === 'en' ? 'Eng' : 'Қаз'}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => changeLanguage('ru')}>Русский</Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('en')}>English</Dropdown.Item>
                <Dropdown.Item onClick={() => changeLanguage('kk')}>Қазақша</Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {isScrolled && (
        <div className="bottom-nav bg-light d-flex justify-content-around align-items-center p-2 d-lg-none position-fixed bottom-0 w-100 border-top">
          <Nav.Link as={Link} to="/" className="nav-item text-center">
            <i className="bi bi-house-door"></i>
            <small>{t('home')}</small>
          </Nav.Link>
          <Nav.Link onClick={handleAboutClick} className="nav-item text-center">
            <i className="bi bi-info-circle"></i>
            <small>{t('about')}</small>
          </Nav.Link>
          <Nav.Link as={Link} to="/products" className="nav-item text-center">
            <i className="bi bi-bag"></i>
            <small>{t('products')}</small>
          </Nav.Link>
          <Nav.Link as={Link} to="/gallery" className="nav-item text-center">
            <i className="bi bi-images"></i>
            <small>{t('gallery')}</small>
          </Nav.Link>
          <Nav.Link onClick={handlePartnersClick} className="nav-item text-center">
            <i className="bi bi-people"></i>
            <small>{t('partners')}</small>
          </Nav.Link>
        </div>
      )}
    </>
  );
}

export default Header;
